import { useFirestoreUser } from './useFirestoreUser'
import { useMemo } from 'react'

export function useCrosslistingConfig () {
  const user = useFirestoreUser()
  const role = user.private?.subscription?.cl_role
  
  return useMemo(() => {
    return {
      isCLIndividual: role === 'individual',
      isCLAdmin: role === 'admin',
      isShopifyEnabled: !!user.private?.subscription?.cl_shopify_store_id,
      isWixEnabled: !!user.private?.subscription?.cl_wix_site_id,
      weeklyLimit: user.private?.subscription?.cl_weekly_postings ?? 1
    }
  }, [user.private?.subscription])
}