import Link from 'next/link'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import BrandLogo from './BrandLogo'
import styles from '../../styles/components/AppIntroduction.module.css'
import SubscribeViaEmailForm from '../SubscribeViaEmailForm'
import Nav from 'react-bootstrap/Nav'
import NavLink from 'react-bootstrap/NavLink'
import { useRouter } from 'next/router'
import AppMarketplaceLink from '../AppMarketplaceLink'

const productLinks = [
  { href: '/ivy-industry', label: 'Ivy Industry' },
  { href: '/marketplace-notifications', label: 'Marketplace Notifications' },
  { href: '/listing-manager', label: 'Listing Manager' },
  { href: '/ivy-store', label: 'Ivy Store' },
  { href: '/sales-tracker', label: 'Sales Tracker' }
]

const otherLinks = [
  { href: '/pricing', label: 'Pricing' },
  { href: '/resources', label: 'Resources' },
  { href: '/blog', label: 'Blog' },
  { href: '/faq', label: 'FAQ' },
  { href: '/about', label: 'About' }
]

const Footer = () => {
  const router = useRouter()

  return (
    <div className='py-4 py-lg-5 px-4 px-lg-5 my-3 bg-primary text-white br-3 mt-5'>
      <Row>
        <Col sm={12} md={3}>
          <div className='mb-4 mb-md-5'>
            <BrandLogo big />
          </div>

          <div
            className={`${styles.marketplaces} d-flex gap-2 flex-row flex-md-column w-100 w-md-50 mb-5 mb-md-0`}
            style={{ maxWidth: 200 }}
          >
            <AppMarketplaceLink marketplace='apple' />
            <AppMarketplaceLink marketplace='google' />
          </div>
        </Col>
        <Col sm={0} md={3}>
          <div
            style={{ padding: '0.5rem 2rem', paddingTop: 0, paddingLeft: 0 }}
            className="fw-semibold fs-4"
          >
            Products
          </div>
          <Nav
            className="d-flex flex-row flex-md-column mb-5 mb-md-0"
            activeKey={router.pathname}
          >
            {productLinks.map((link, index) => (
              <NavLink
                key={index}
                href={link.href}
                className="ps-0"
              >
                {link.label}
              </NavLink>
            ))}
          </Nav>
        </Col>
        <Col sm={12} md={3}>
          <div
            style={{ padding: '0.5rem 2rem', paddingTop: 0, paddingLeft: 0 }}
            className="fw-semibold fs-4"
          >
            Explore
          </div>
          <Nav
            className="d-flex flex-row flex-md-column mb-5 mb-md-0"
            activeKey={router.pathname}
          >
            {otherLinks.map((link, index) => (
              <NavLink
                key={index}
                href={link.href}
                className="ps-0"
              >
                {link.label}
              </NavLink>
            ))}
          </Nav>
        </Col>
        <Col
          sm={12} md={3}
          className='d-flex flex-column'
        >
          <div>
            <SubscribeViaEmailForm shownInFooter />
          </div>
        </Col>
        <Col
          xs={12}
          className='text-center mt-5'
        >
          <div
            className="border border-1 border-light border-opacity-10 mb-5"
          />
          <div className='mb-3'>
            We are on a mission to create opportunities for people.
          </div>
          <div className=''>
            © 2023 — Ivy Flip — <Link href='/legal/privacy-policy' prefetch={false}>View Privacy Policy</Link>
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default Footer