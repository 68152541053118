import Button from 'react-bootstrap/Button'
import Link from 'next/link'

export default function PrimaryCtaButton ({
  label = 'Start Free Trial',
  subtitle = '7 Days Free Trial • Cancel Anytime',
  variant = 'success',
  href = '/app/subscription?new=growth'
}) {
  return (
    <div className={'d-flex flex-column gap-3'}>
      <Link href={href}>
        <Button
          variant={variant}
          size='lg'
          className={`px-5 py-3 w-100`}
        >
          {label}
        </Button>
      </Link>

      {subtitle && (
        <div className="text-center fs-6 opacity-50">
          {subtitle}
        </div>
      )}
    </div>
  )
}
