import Container from 'react-bootstrap/Container'
import Link from 'next/link'
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'
import NavDropdown from 'react-bootstrap/NavDropdown'
import { useUser } from 'next-firebase-auth'
import { useRouter } from 'next/router'
import BrandLogo from './BrandLogo'
import { useCrosslistingConfig } from '../../hooks/useCrosslistingConfig'
import { useFirestoreUser } from '../../hooks/useFirestoreUser'
import { pickupRegions } from '../../lib/data/pickup-regions'
import FooterSmall from './FooterSmall'
import NavLink from './NavLink'
import NavDropdownLink from './NavDropdownLink'
import MenuItemsList from './MenuItemsList'
import { ListingManagerWalkthrough } from '../listing-manager/ListingManagerWalkthrough'

const LayoutApp = ({ children }) => {
  const authUser = useUser()
  const router = useRouter()
  const { isCLAdmin} = useCrosslistingConfig()

  const user = useFirestoreUser()
  const leadsAccess = user.private?.subscription?.leads_access

  const crosslistingMenuItems = [
    {
      available: true,
      href: '/app/listing-manager',
      label: 'Listing Manager'
    },
    {
      available: true,
      href: '/store/settings',
      label: 'Ivy Store'
    },
    {
      available: true,
      href: '/app/sales-tracker',
      label: 'Sales Tracker'
    },
    ...(user?.private?.subscription?.pickup_region_access ?? [])
      .filter(region => pickupRegions[region])
      .map(region => ({
        available: true,
        href: `/pickup/feed?region=${region}`,
        label: <>
          Pickup Feed
          <div style={{ fontSize: 12, marginTop: -4 }}>
            {pickupRegions[region].name}
          </div>
        </>
      })),
  ]

  const userMenuItems = [
    {
      available: true,
      href: '/app/subscription',
      label: 'Subscription'
    },
    {
      available: true,
      href: '/app/settings',
      label: 'Settings'
    }
  ]

  const adminMenuItems = [
    {
      // available: authUser.claims?.leads === true,
      available: leadsAccess,
      href: '/leads',
      label: 'Lead Cannon'
    },
    {
      available: authUser.claims?.messages === true,
      href: '/messages',
      label: 'Message Blast'
    },
    {
      available: isCLAdmin,
      href: '/app/crosslisting/admin',
      label: 'CL Admin'
    },
    {
      available: isCLAdmin,
      href: '/app/couch-pickup/dashboard',
      label: 'Pickup Admin'
    },
    {
      available: isCLAdmin,
      href: '/app/store-admin',
      label: 'Store Admin'
    }
  ].filter(item => item.available)

  return (
    <>
      <Navbar
        bg='white'
        variant='light'
        expand='md'
        className='py-3 py-md-2 mb-sm-2'
        collapseOnSelect
      >
        <Container>
          <Link
            href={'/app'}
            prefetch={false}
          >
            <Navbar.Brand
              className='d-flex gap-3 me-4'
              href='/'
            >
              <BrandLogo />
            </Navbar.Brand>
          </Link>
          <Navbar.Toggle aria-controls='basic-navbar-nav' />
          <Navbar.Collapse id='basic-navbar-nav'>
            <Nav
              className='me-auto align-items-md-center mt-4 mt-md-0 ms-md-3'
              activeKey={router.pathname}
            >
              {crosslistingMenuItems.map(item => <NavLink key={item.href} href={item.href}>{item.label}</NavLink>)}
            </Nav>
            <Nav activeKey={router.pathname}>
              {authUser.id ? (
                <NavDropdown
                  title='Account'
                  className='my-0 position-relative'
                  style={{ zIndex: 1050 }}
                >
                  <MenuItemsList items={adminMenuItems} />
                  <MenuItemsList items={userMenuItems} divide={adminMenuItems.length > 0} />
                  <NavDropdown.Divider />
                  <NavDropdownLink href={'/'}>
                    To website
                  </NavDropdownLink>
                </NavDropdown>
              ) : (
                <NavLink href='/app/signin'>Sign In</NavLink>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Container className='d-flex flex-column'>
        {/*<NewsBanner />*/}

        {children}

        <ListingManagerWalkthrough />

        <FooterSmall />
      </Container>
    </>
  )
}

export default LayoutApp
