import Link from 'next/link'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import { VideoTestimonials } from '../components/landing/VideoTestimonials'
import PrimaryCtaButton from '../components/landing/PrimaryCtaButton'
import Hero from '../components/landing/Hero'
import Features from '../components/landing/Features'
import Seo from '../components/blog/seo'
import { fetchSEO } from '../lib/blog/api'
import Quote from '../components/landing/Quote'
import TextTestimonials from '../components/landing/TextTestimonials'
import FeatureImage from '../components/landing/FeatureImage'
import AppIntroduction from '../components/landing/AppIntroduction'
import Accent from '../components/landing/Accent'
import Block from '../components/landing/elements/Block'
import Card from '../components/landing/elements/Card'
import Title from '../components/landing/elements/Title'
import Text from '../components/landing/elements/Text'
import CtaButton from '../components/landing/elements/CtaButton'
import Image from '../components/landing/elements/Image'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlayCircle } from '@fortawesome/free-solid-svg-icons'

const Index = ({ seo }) => {
  return (
    <>
      <Seo seo={seo} />

      {/* Hero */}
      <Hero
        title="Unleash Your Flippin' Potential"
        text="All the tools you need to profitably grow your flipping business!"
        buttons={(
          <Col xs={12} md={6} lg={6} xl={4}>
            <PrimaryCtaButton />
          </Col>
        )}
      />
      <Quote
        text="Thanks to Ivy, I went from flipping 3 couches to 10 per week!"
        name="Alex C."
        image="/img/landing/persons/person-alex.jpg"
      />

      <VideoTestimonials />

      <AppIntroduction />

      <Features
        title="Our Products, Your Force Multipliers"
        items={[
          {
            title: 'Find the Best Deals',
            text: <>
              Get instant alerts when local deals are posted near you.
              Start hearing, <Accent type='success'>Yes, it's available</Accent> instead
              of <Accent type='danger'>No, it's pending sale</Accent>.<br/>
              Plus see <span className='fw-semibold'>2x – 3x more</span> deals compared to manual searching!
            </>,
            buttons: (
              <Link href={'/marketplace-notifications'}>
                <Button variant={'light'}>Learn more</Button>
              </Link>
            ),
            image: (
              <FeatureImage
                src='/img/landing/index/new-1.png'
                title='Find the Best Deals'
              />
            )
          },
          {
            title: 'Reach More Customers',
            text: <>
              <Accent type='success'>Generate 15 - 20% more revenue</Accent> with our innovative
              crosslisting feature!<br/>
              We let you list on multiple marketplaces with the click of a button.
            </>,
            buttons: (
              <Link href={'/listing-manager'}>
                <Button variant={'light'}>Learn more</Button>
              </Link>
            ),
            image: (
              <FeatureImage
                src='/img/landing/index/new-2.png'
                title='Reach More Customers'
              />
            )
          },
          {
            title: 'Get Organized',
            text: <>
              Track all of your inventory and sales directly in the app.<br/>
              Find opportunities for growth with clear financial visibility into your business.
            </>,
            buttons: (
              <Link href={'/sales-tracker'}>
                <Button variant={'light'}>Learn more</Button>
              </Link>
            ),
            image: (
              <FeatureImage
                src='/img/landing/index/new-3.png'
                title='Get Organized'
              />
            )
          },
          {
            title: 'Ivy Store',
            text: <>
              Your own storefront to showcase inventory with style.
              <br/>
              Get In Front of More Buyers.
              <br/>
              Make More Money. <Accent type='success'>It’s That Simple</Accent>.
            </>,
            buttons: (
              <Link href={'/ivy-store'}>
                <Button variant={'light'}>Learn more</Button>
              </Link>
            ),
            image: (
              <FeatureImage
                src='/img/landing/next/ivy-store/index-ivy-store.png'
                title='Get Organized'
              />
            )
          }
        ]}
        background={'bg-primary'}
        color='text-white'
      />

      <TextTestimonials />

      <Block
        background={'bg-primary'}
        color={'text-white'}
        backgroundImage={'/img/landing/next/ivy-industry/bg.jpg'}
        leftGradient
        noAppearanceAnimation
      >
        <Col xs={12} md={8}>
          <div className={'py-3 py-md-5 my-md-5'}>
            <Card gap={5} className={'px-5'}>
              <div>
                <Title
                  size={1}
                  h
                  display
                >
                  Ivy Industry
                </Title>
                <Text size={4} className={'fst-italic text-success'} accent>
                  Building the Next Generation
                  <br/>
                  of 7-figure Couch Flipping Businesses
                </Text>
              </div>

              <div className={'d-flex flex-column gap-4'}>
                <Text size={4} accent>
                  Tired of flipping alone? Want to join a winning team?
                </Text>
                <Text size={5} accent>
                  For those who want to build the next generation of muti-million dollar furniture businesses — this partnership is for you.
                </Text>
              </div>

              <div className="d-flex">
                <CtaButton
                  label={'Apply Now'}
                  href={'/ivy-industry-application'}
                />
              </div>
            </Card>
          </div>
        </Col>
      </Block>

      <div className={'mt-5'}>
        <Block
          alignVertical
          noAppearanceAnimation
        >
          <Col xs={12} md={{ offset: 1, span: 5}}>
            <Card>
              <Image
                src={'/img/landing/next/couch-flippers/podcast.png'}
                height={350}
                className={'bg-light'}
                contain
              />
            </Card>
          </Col>
          <Col xs={12} md={5}>
            <Card>
              <Title size={3}>
                Tune Into the World’s #1
                <br/>
                Couch Flipping Podcast
              </Title>
              <Text>
                Weekly episodes with top flippers sharing their secrets, strategies, and success stories.
              </Text>
              <Text>
                Listen in and level up your game.
              </Text>

              <div className={'d-inline-block'}>
                <Button
                  variant={'success'}
                  size={'lg'}
                  className={'px-3'}
                  href={'https://www.youtube.com/@ivyflip'}
                  target={'_blank'}
                >
                  <FontAwesomeIcon
                    icon={faPlayCircle}
                    width={24}
                    height={24}
                    className={'me-2'}
                  />
                  Listen Now
                </Button>
              </div>
            </Card>
          </Col>
        </Block>
      </div>
    </>
  )
}

export async function getStaticProps () {
  const seo = await fetchSEO('index')
  return {
    props: { seo },
    revalidate: 60 * 60 * 24
  }
}

export default Index
