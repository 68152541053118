import Button from 'react-bootstrap/Button'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import {
  faArrowLeft,
  faArrowRight,
  faEllipsisVertical,
  faListNumeric,
  faPlus,
  faQuestionCircle,
  faTimes
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Badge from 'react-bootstrap/Badge'
import Link from 'next/link'

const chapters = [
  {
    id: 1,
    title: 'Welcome to Listing Manager',
    text: <>
      <div>
        Welcome to <strong>Listing Manager</strong>, your integrated, one-stop solution for managing product listings across multiple marketplaces.
        This walkthrough will guide you through the primary features and help you get started quickly.
      </div>
      <div>
        You can hide this tutorial at any point and bring it back by clicking the Walkthrough button in the bottom-right corner.
      </div>
    </>
  },
  {
    id: 2,
    title: 'Create your First Draft',
    text: <>
      <div>
        In the top-right corner, click the <Button
        variant={'success'}
        size={'sm'}
        className={'mx-1'}
      >
        <FontAwesomeIcon
          icon={faPlus}
          width={16}
          height={16}
          className={'me-1'}
        />
        Draft
      </Button> button to start creating your first listing draft.
      </div>
      <div>
        Once you’ve clicked it, press <strong>Next</strong> to continue the Walkthrough.
      </div>
    </>
  },
  {
    id: 3,
    title: 'Create the Master Listing',
    text: <>
      <div>
        Upload at least one image and fill in the listing details on the provided form.
      </div>
      <div>
        A real-time preview of your listing will be visible on the right.
      </div>
      <div>
        To finalize your first draft, click the <Button
        variant={'success'}
        size={'sm'}
        className={'mx-1'}
      >
        Create Draft
      </Button> button located on the top panel.
      </div>
    </>
  },
  {
    id: 4,
    title: 'Add Marketplaces',
    text: <>
      <div>
        In the center of the page, update your listing details.
      </div>
      <div>
        On the left side, you’ll see a list of marketplaces where your listing can be published.
        By default, <strong>Ivy Store</strong> is already included.
      </div>
      <div>
        To add <Button
        variant={'light'}
        size={'sm'}
        className={'mx-1'}
      >
        <FontAwesomeIcon
          icon={faPlus}
          width={16}
          height={16}
          className={'me-1'}
        />
          Facebook
        </Button> as a target, click the + button next to the marketplace list.
      </div>
      <div>
        Any marketplaces you add will inherit their attributes from your master listing.
      </div>
    </>
  },
  {
    id: 5,
    title: 'Customize Marketplaces',
    text: <>
      <div>
        You can customize how your listing appears on each marketplace in addition to mapping to additional fields.
        Let’s walk through an example.
      </div>
      <div>
        Suppose you’d like to upload a video to Facebook Marketplace.
        On the left side, select <strong>Facebook</strong> from the marketplace list.
      </div>
      <div>
        You’ll see a unique form for Facebook, including fields for <strong>Video</strong>,
        <strong>Meeting Preferences</strong>, <strong>Sofa Type</strong>, <strong>Color</strong>, and <strong>Decor Style</strong>.
        Fill these in as needed, then click <strong>Save</strong>.
      </div>
      <div>
        Below that, you’ll see shared fields that automatically inherit values from the <strong>Master Listing</strong>.
        Changes you make in the Master Listing will sync here—unless you choose to <strong>Customise</strong> them.
      </div>
      <div>
        For example, click the <strong>Customise</strong> button next to Title to create a Facebook-specific
        title that stays independent of any future changes in the Master Listing.
      </div>
    </>
  },
  {
    id: 6,
    title: 'Listing Statuses',
    text: <>
      <div>
        Return to the main page (index) by clicking the <Button
          variant={'light'}
          size={'sm'}
          className={'mx-1'}
          >
          <FontAwesomeIcon
            icon={faArrowLeft}
            width={16}
            height={16}
            className={'me-1'}
          />
          Listing Manager
        </Button> button.
      </div>
      <div>
        Notice the horizontal status bar with these tabs:
      </div>
      <div>
        <strong>Drafts</strong>: Unpublished listings.
      </div>
      <div>
        <strong>Active</strong>: Listings that are currently live on at least one marketplace.
      </div>
      <div>
        <strong>Sold</strong>: Listings you’ve marked as sold and removed from marketplaces
        (also added to <Link href={'/app/sales-tracker'}>Sales Tracker</Link>).
      </div>
      <div>
        <strong>Deleted</strong>: Listings you’ve decided to remove permanently.
      </div>
      <div>
        In each status tab, you can click the <Button
        variant={'light'}
        size={'sm'}
        className={'mx-1'}
      >
        <FontAwesomeIcon
          icon={faEllipsisVertical}
          width={16}
          height={16}
        />
        </Button> menu icon beside a listing to <strong>edit</strong>, <strong>clone</strong>, or <strong>delete</strong> it.
      </div>
    </>
  },
  {
    id: 7,
    title: 'Introducing Templates',
    text: <>
      <div>
        <strong>Templates</strong> are a powerful time-saver for creating multiple, similar listings.
      </div>
      <div>
        You post a lot of similar items and you want to reuse the same title, description, etc? Templates are for you.
      </div>
      <div>
        Return to the main page and click <Button
        variant={'light'}
        size={'sm'}
        className={'mx-1'}
      >
        <FontAwesomeIcon
          icon={faPlus}
          width={16}
          height={16}
          className={'me-1'}
        />
        Template
      </Button> at the top-right corner to create your first template.
      </div>
    </>
  },
  {
    id: 8,
    title: 'Using Templates in Detail',
    text: <>
      <div>
        After clicking <strong>New Template</strong>, fill in the Title or any other fields you’d like to reuse.
      </div>
      <div>
        On the left, add <strong>Facebook Marketplace</strong> (or any other marketplace) by clicking the + button.
      </div>
      <div>
        Click <Button
        variant={'light'}
        size={'sm'}
        className={'mx-1'}
      >
        <FontAwesomeIcon
          icon={faPlus}
          width={16}
          height={16}
          className={'me-1'}
        />
        Facebook
      </Button> to finalize your template.
      </div>
      <div>
        Back on the <strong>Templates</strong> list, click <strong>+ Draft</strong> to spin up a new listing using the template.
      </div>
      <div>
        Notice that the new listing automatically populates with your template’s title and includes Facebook Marketplace as a target.
      </div>
    </>
  },
  {
    id: 9,
    title: 'Advanced Templates',
    text: <>
      <div>
        For more customization, you can set marketplace-specific fields within a template.
      </div>
      <div>
        Create a <strong>New Template</strong> and fill in a general title in the Master Listing.
      </div>
      <div>
        Add <strong>Facebook Marketplace</strong> on the left, then click <strong>Facebook</strong> to unlock and customize a separate title (or other fields) just for Facebook.
      </div>
      <div>
        Save the template and create a draft based on it.
      </div>
    </>
  },
  {
    id: 10,
    title: 'Menu'
  }
]

const keyDismiss = 'listing-manager-walkthrough-dismissed'
const keyChapter = 'listing-manager-walkthrough-chapter'

export function ListingManagerWalkthrough () {
  const router = useRouter()
  const isFabVisible = router.asPath.includes('/app/listing-manager')

  // on first load, show the modal asking if the user wants to see the walkthrough
  // if the user clicks "No" - we do not show the modal again, but keep the FAB
  const dismissed = localStorage.getItem(keyDismiss)
  const [isWalkthroughVisible, setIsWalkthroughVisible] = useState(!dismissed)

  const storedChapter = parseInt(localStorage.getItem(keyChapter) ?? 0)
  const [activeChapterKey, setActiveChapterKey] = useState(storedChapter)
  const activeChapter = chapters[activeChapterKey]

  useEffect(() => {
    localStorage.setItem(keyChapter, activeChapterKey.toString())
  }, [activeChapterKey])

  if (!isFabVisible) {
    return
  }

  return (
    <>
      {/* FAB */}
      {!isWalkthroughVisible && (
        <div className={'position-fixed end-0 bottom-0 me-3 mb-3'}>
          <Button
            variant={'info'}
            onClick={() => setIsWalkthroughVisible(true)}
          >
            Walkthrough
            <FontAwesomeIcon
              icon={faQuestionCircle}
              width={16}
              height={16}
              className={'ms-2'}
            />
          </Button>
        </div>
      )}

      {/* Walkthrough */}
      {isWalkthroughVisible && (
        <div
          className={'position-fixed end-0 bottom-0 me-3 mb-3'}
          style={{
            width: 500,
            maxWidth: 'calc(100vw - 2rem)',
            zIndex: 1051,
          }}
        >
          <div className={'border border-5 border-info br-3 p-3 bg-white'}>
            <div className={'d-flex flex-column gap-3'}>
              {/* Header */}
              <div className={'d-flex align-items-center gap-3'}>
                <Badge
                  pill
                  bg={'info'}
                  onClick={() => setActiveChapterKey(9)}
                  style={{ cursor: 'pointer' }}
                >
                  <FontAwesomeIcon
                    icon={faListNumeric}
                    width={16}
                    height={16}
                    className={'me-1'}
                  />

                  Step {activeChapter.id} / {chapters.length}
                </Badge>
                <div className={'text-muted'}>
                  Listing Manager Walkthrough
                </div>
                <Button
                  variant={'light'}
                  className={'ms-auto'}
                  onClick={() => setIsWalkthroughVisible(false)}
                >
                  <FontAwesomeIcon
                    icon={faTimes}
                    width={16}
                    height={16}
                  />
                </Button>
              </div>
              {/* Step Header */}
              <div className={'d-flex align-items-baseline gap-2 fs-5 '}>
                <div>
                  {activeChapter.title}
                </div>
              </div>
              {/* Body */}
              <div
                className={'d-flex flex-column gap-3'}
                style={{
                  maxHeight: '60vh',
                  overflowY: 'auto'
                }}
              >
                {activeChapter.text}

                {activeChapter.id === 10 && (
                  <div>
                    {chapters.map(chapter => (
                      <div key={chapter.id}>
                        <Button
                          variant={'link'}
                          onClick={() => setActiveChapterKey(chapter.id - 1)}
                        >
                          {chapter.id}. {chapter.title}
                        </Button>
                      </div>
                    ))}
                  </div>
                )}
              </div>
              {/* Buttons */}
              <div className={'d-flex justify-content-between gap-2'}>
                {activeChapter.id === 1 && (
                  <>
                    <div/>
                    <Button
                      variant={'primary'}
                      onClick={() => setActiveChapterKey(activeChapterKey + 1)}
                    >
                      Start Walkthrough
                      <FontAwesomeIcon
                        icon={faArrowRight}
                        width={16}
                        height={16}
                        className={'ms-2'}
                      />
                    </Button>
                  </>
                )}

                {activeChapter.id > 1 && (
                  <>
                    <Button
                      variant={'light'}
                      onClick={() => setActiveChapterKey(activeChapterKey - 1)}
                    >
                      <FontAwesomeIcon
                        icon={faArrowLeft}
                        width={16}
                        height={16}
                        className={'me-2'}
                      />
                      Previous
                    </Button>

                    {(activeChapterKey < chapters.length - 1) && (
                      <Button
                        variant={'primary'}
                        onClick={() => setActiveChapterKey(activeChapterKey + 1)}
                      >
                        Next
                        <FontAwesomeIcon
                          icon={faArrowRight}
                          width={16}
                          height={16}
                          className={'ms-2'}
                        />
                      </Button>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}