import * as app from 'firebase/app'
import * as db from 'firebase/firestore'
import * as auth from 'firebase/auth'
import * as fns from 'firebase/functions'
import * as stor from 'firebase/storage'
import absoluteUrl from 'next-absolute-url'
import { init } from 'next-firebase-auth'

let firebaseFunctions

const initFirebase = () => {
  if (typeof window !== 'undefined') {
    // Initialize Firebase client
    const firebaseApp = app.initializeApp({
      projectId: process.env.FIREBASE_PROJECT_ID,
      apiKey: process.env.FIREBASE_API_KEY,
      authDomain: process.env.FIREBASE_AUTH_DOMAIN,
      messagingSenderId: process.env.FIREBASE_MESSAGING_SENDER_ID,
      appId: process.env.FIREBASE_APP_ID,
      storageBucket: process.env.FIREBASE_STORAGE_BUCKET
    })

    // Connect emulators, if necessary
    if (process.env.FIREBASE_EMULATOR_FIRESTORE_HOST) {
      console.log('Connecting Firestore emulator')
      db.connectFirestoreEmulator(
        db.getFirestore(),
        process.env.FIREBASE_EMULATOR_FIRESTORE_HOST,
        process.env.FIREBASE_EMULATOR_FIRESTORE_PORT)
    }

    if (process.env.FIREBASE_EMULATOR_FUNCTIONS_HOST) {
      console.log('Connecting Cloud Functions emulator')
      fns.connectFunctionsEmulator(
        fns.getFunctions(firebaseApp, 'us-west1'),
        process.env.FIREBASE_EMULATOR_FUNCTIONS_HOST,
        process.env.FIREBASE_EMULATOR_FUNCTIONS_PORT)
    }

    if (process.env.FIREBASE_EMULATOR_STORAGE_HOST) {
      console.log('Connecting Storage emulator')
      stor.connectStorageEmulator(
        stor.getStorage(),
        process.env.FIREBASE_EMULATOR_STORAGE_HOST,
        process.env.FIREBASE_EMULATOR_STORAGE_PORT
      )
    }

    firebaseFunctions = fns.getFunctions(firebaseApp, 'us-west1')

    // todo: fix this - we need to check if auth emulator enabled
    if (process.env.FIREBASE_EMULATOR_STORAGE_HOST) {
      // console.log('Connecting Auth emulator')
      auth.connectAuthEmulator(auth.getAuth(), 'http://localhost:4101')
    } else {
      // Set some defaults
      // auth.setPersistence(auth.getAuth(), auth.browserLocalPersistence)
      // https://github.com/firebase/firebase-js-sdk/issues/4946#issuecomment-921147692
      auth.initializeAuth(firebaseApp, { persistence: [auth.browserLocalPersistence] })
    }
  }

  // Set up next-firebase-auth package
  init({
    authPageURL: ({ ctx }) => {
      const isServerSide = typeof window === 'undefined'
      const origin = isServerSide ? absoluteUrl(ctx.req).origin : window.location.origin
      const destinationPath = isServerSide ? ctx.resolvedUrl : window.location.href
      const destinationUrl = new URL(destinationPath, origin)
      return `/app/signin?destination=${encodeURIComponent(destinationUrl)}`
    },
    appPageURL: ({ ctx }) => {
      const isServerSide = typeof window === 'undefined'
      const origin = isServerSide ? absoluteUrl(ctx.req).origin : window.location.origin
      const params = isServerSide ? new URL(ctx.req.url, origin).searchParams : new URLSearchParams(window.location.search)
      const destinationParamValue = params.get('destination') ? decodeURIComponent(params.get('destination')) : undefined
      let destinationUrl = '/app'
      if (destinationParamValue) {
        // Redirect to provided destination if allowed
        // https://owasp.org/www-project-web-security-testing-guide/v41/4-Web_Application_Security_Testing/11-Client_Side_Testing/04-Testing_for_Client_Side_URL_Redirect
        const allowedHosts = ['localhost:3000', 'ivyflip.com', 'www.ivyflip.com', 'dev.ivyflip.com', 'app.ivyflip.com']
        const isAllowed = allowedHosts.indexOf(new URL(destinationParamValue).host) > -1
        if (isAllowed) {
          destinationUrl = destinationParamValue
        } else {
          console.warn(`Redirect destination host must be one of ${allowedHosts.join(', ')}`)
        }
      }
      return destinationUrl
    },
    loginAPIEndpoint: '/api/login',
    logoutAPIEndpoint: '/api/logout',
    onLoginRequestError: (err) => console.error('onLoginRequestError', err),
    onLogoutRequestError: (err) => console.error('onLogoutRequestError', err),
    useFirebaseAdminDefaultCredential: !process.env.FIREBASE_PRIVATE_KEY,
    firebaseAdminInitConfig: {
      credential: {
        projectId: process.env.FIREBASE_PROJECT_ID,
        clientEmail: process.env.FIREBASE_CLIENT_EMAIL,
        privateKey: process.env.FIREBASE_PRIVATE_KEY
      }
    },
    firebaseClientInitConfig: {
      apiKey: process.env.FIREBASE_API_KEY
    },
    cookies: {
      name: 'ivy-flip',
      signed: true,
      secure: !process.env.FIREBASE_EMULATOR_FIRESTORE_HOST,
      keys: [
        process.env.COOKIE_SECRET_CURRENT,
        process.env.COOKIE_SECRET_PREVIOUS
      ],
      maxAge: 12 * 60 * 60 * 24 * 1000, // 12 days
      path: '/',
      sameSite: 'lax',
      httpOnly: true
    },
    onVerifyTokenError: (err) => console.error('onVerifyTokenError', err),
    onTokenRefreshError: (err) => console.error('onTokenRefreshError', err),
    firebaseAuthEmulatorHost: typeof window !== 'undefined'
      ? process.env.FIREBASE_CLIENT_AUTH_EMULATOR_HOST
      : process.env.FIREBASE_AUTH_EMULATOR_HOST
  })
}

export { initFirebase, firebaseFunctions }
